/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
    font-family: "Open Sans", sans-serif;
    color: #444444;
  }
  
  a {
    color: #1977cc;
    text-decoration: none;
  }
  
  a:hover {
    color: #3291e6;
    text-decoration: none;
  }
  
  h1, h2, h3, h4, h5, h6 {
    font-family: "Noto Sans JP", sans-serif;
  }
  
  /*--------------------------------------------------------------
  # Preloader
  --------------------------------------------------------------*/
  #preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    overflow: hidden;
    background: #fff;
  }
  
  #preloader:before {
    content: "";
    position: fixed;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
    border: 6px solid #1977cc;
    border-top-color: #d1e6f9;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    -webkit-animation: animate-preloader 1s linear infinite;
    animation: animate-preloader 1s linear infinite;
  }
  
  @-webkit-keyframes animate-preloader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes animate-preloader {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  /*--------------------------------------------------------------
  # Back to top button
  --------------------------------------------------------------*/
  .back-to-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 25px;
    bottom: 15px;
    z-index: 996;
    background: #1977cc;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    transition: all 0.4s;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -ms-transition: all 0.4s;
    -o-transition: all 0.4s;
}
  .back-to-top i {
    font-size: 28px;
    color: #fff;
    line-height: 0;
  }
  .back-to-top:hover {
    background: #298ce5;
    color: #fff;
  }
  .back-to-top.active {
    visibility: visible;
    opacity: 1;
  }
  
  .datepicker-dropdown {
    padding: 20px !important;
  }
  
  /*--------------------------------------------------------------
  # Top Bar
  --------------------------------------------------------------*/
  #topbar {
    background: #fff;
    height: 0;
    font-size: 14px;
    transition: all 0.5s;
    z-index: 996;
  }
  #topbar.topbar-scrolled {
    top: -40px;
  }
  #topbar .contact-info a {
    line-height: 1;
    color: #444444;
    transition: 0.3s;
  }
  #topbar .contact-info a:hover {
    color: #1977cc;
  }
  #topbar .contact-info i {
    color: #1977cc;
    padding-right: 4px;
    margin-left: 15px;
    line-height: 0;
  }
  #topbar .contact-info i:first-child {
    margin-left: 0;
  }
  #topbar .social-links a {
    color: #437099;
    padding-left: 15px;
    display: inline-block;
    line-height: 1px;
    transition: 0.3s;
  }
  #topbar .social-links a:hover {
    color: #1977cc;
  }
  #topbar .social-links a:first-child {
    border-left: 0;
  }
  
  /*--------------------------------------------------------------
  # Header
  --------------------------------------------------------------*/
  #header {
    background: #fff;
    transition: all 0.5s;
    z-index: 997;
    padding: 15px 0;
    top: 0;
    box-shadow: 0px 2px 15px rgba(25, 119, 204, 0.1);
  }
  #header.header-scrolled {
    top: 0;
  }
  #header .logo {
    font-size: 24px;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-weight: 700;
    letter-spacing: 0.5px;
    font-family: "Poppins", sans-serif;
  }
  @media (min-width: 768px) {
    #header .logo {
      font-size: 30px;
    }
  }
  #header .logo a {
    color: #2c4964;
  }
  #header .logo img {
    max-height: 40px;
  }
  #header .logobold {
    letter-spacing: 0.005rem;
  }
  /**
  * Appointment Button *
  */
  .appointment-btn {
    /* margin-left: 25px; */
    background: #1977cc;
    color: #fff!important;
    border-radius: 50px;
    padding: 8px 25px;
    white-space: nowrap;
    transition: 0.3s;
    font-size: 16px;
    display: inline-block;
    border: none;
  }
  .appointment-btn:hover {
    background: #166ab5;
    color: #fff;
  }
  @media (max-width: 991px) {
    .appointment-btn {
      margin: 0 15px 0 0;
      padding: 6px 18px;
    }
  }

  .appointment-btn.muted {
    background-color: #D9E7FA;
  }

  .appointment-btn.mega-btn {
    text-align: center;
    margin: 0 auto;
    padding: 1rem;
    width: 100%;
  }
  
  /*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
  /**
  * Desktop Navigation 
  */
  .navbar {
    padding: 0;
  }
  .navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }
  .navbar li {
    position: relative;
  }
  .navbar > ul > li {
    position: relative;
    white-space: nowrap;
    padding: 8px 0 8px 20px;
  }
  .navbar a, .navbar a:focus {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    color: #2c4964;
    white-space: nowrap;
    transition: 0.3s;
    border-bottom: 2px solid #fff;
    padding: 5px 2px;
  }
  @media (min-width: 992px) {
    .navbar a, .navbar a:focus {
      justify-content: space-between;
    }
  }
  .navbar a i, .navbar a:focus i {
    font-size: 12px;
    line-height: 0;
    margin-left: 5px;
  }
  .navbar a:hover, .navbar .active, .navbar .active:focus, .navbar li:hover > a {
    color: #1977cc;
    border-color: #1977cc;
  }
  .navbar .dropdown ul {
    display: block;
    position: absolute;
    left: 20px;
    top: calc(100% + 30px);
    margin: 0;
    padding: 10px 0;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    transition: 0.3s;
  }
  .navbar .dropdown ul li {
    min-width: 200px;
  }
  .navbar .dropdown ul a {
    padding: 10px 20px;
    font-size: 14px;
    font-weight: 500;
    text-transform: none;
    color: #082744;
    border: none;
  }
  .navbar .dropdown ul a i {
    font-size: 12px;
  }
  .navbar .dropdown ul a:hover, .navbar .dropdown ul .active:hover, .navbar .dropdown ul li:hover > a {
    color: #1977cc;
  }
  .navbar .dropdown:hover > ul {
    opacity: 1;
    top: 100%;
    visibility: visible;
  }
  .navbar .dropdown .dropdown ul {
    top: 0;
    left: calc(100% - 30px);
    visibility: hidden;
  }
  .navbar .dropdown .dropdown:hover > ul {
    opacity: 1;
    top: 0;
    left: 100%;
    visibility: visible;
  }
  @media (max-width: 1366px) {
    .navbar .dropdown .dropdown ul {
      left: -90%;
    }
    .navbar .dropdown .dropdown:hover > ul {
      left: -100%;
    }
  }
  
  /**
  * Mobile Navigation 
  */
  .mobile-nav-toggle {
    color: #2c4964;
    font-size: 28px;
    cursor: pointer;
    display: none;
    line-height: 0;
    transition: 0.5s;
  }
  .mobile-nav-toggle.bi-x {
    color: #fff;
  }
  
  @media (max-width: 991px) {
    .mobile-nav-toggle {
      display: block;
    }
  
    .navbar ul {
      display: none;
    }
  }
  .navbar-mobile {
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(28, 47, 65, 0.9);
    transition: 0.3s;
    z-index: 999;
  }
  .navbar-mobile .mobile-nav-toggle {
    position: absolute;
    top: 15px;
    right: 15px;
  }
  .navbar-mobile ul {
    display: block;
    position: absolute;
    top: 55px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    padding: 10px 0;
    background-color: #fff;
    overflow-y: auto;
    transition: 0.3s;
  }
  .navbar-mobile > ul > li {
    padding: 0;
  }
  .navbar-mobile a, .navbar-mobile a:focus {
    padding: 20px 20px;
    font-size: 18px;
    color: #2c4964;
    border: none;
  }
  .navbar-mobile a:hover, .navbar-mobile .active, .navbar-mobile li:hover > a {
    color: #1977cc;
  }
  .navbar-mobile .getstarted, .navbar-mobile .getstarted:focus {
    margin: 15px;
  }
  .navbar-mobile .dropdown ul {
    position: static;
    display: none;
    margin: 10px 20px;
    padding: 10px 0;
    z-index: 99;
    opacity: 1;
    visibility: visible;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  }
  .navbar-mobile .dropdown ul li {
    min-width: 200px;
  }
  .navbar-mobile .dropdown ul a {
    padding: 10px 20px;
  }
  .navbar-mobile .dropdown ul a i {
    font-size: 12px;
  }
  .navbar-mobile .dropdown ul a:hover, .navbar-mobile .dropdown ul .active:hover, .navbar-mobile .dropdown ul li:hover > a {
    color: #1977cc;
  }
  .navbar-mobile .dropdown > .dropdown-active {
    display: block;
  }
  
  /*--------------------------------------------------------------
  # Hero Section
  --------------------------------------------------------------*/

  #hero {
    width: 100%;
    height: 90vh;
    background: url("/img/hero-bg.jpg") top center;
    background-size: cover;
    margin-bottom: -200px;
    display: flex;
    align-items: center;
}
@media (min-width: 768px) {
  #hero {
    transition: background 3s;
    -webkit-transition: background 3s;
    -moz-transition: background 3s;
    -ms-transition: background 3s;
    -o-transition: background 3s;
  }
}
  #hero .container {
    position: relative;
  }
  #hero h1 {
    margin: 0;
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    text-transform: uppercase;
    color: #2c4964;
  }
  #hero h2 {
    color: #2c4964;
    margin: 10px 0 0 0;
    font-size: 16px;
    line-height: 1.6;
  }
  #hero .btn-get-started {
    font-family: "Raleway", sans-serif;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 12px 35px;
    margin-top: 30px;
    border-radius: 50px;
    transition: 0.5s;
    color: #fff;
    background: #1977cc;
    max-width: 300px;
  }
  #hero .btn-get-started:hover {
    background: #3291e6;
  }
  @media (min-width: 1024px) {
    #hero {
      background-attachment: fixed;
    }
  }
  @media (max-width: 767px) {
    #hero {
      margin-bottom: 0;
      height: 100vh;
      background: url("/img/hero-bg-sp.jpg") no-repeat top center / cover;
    }
    #hero .container {
      margin-bottom: -20rem;
    }
    #hero h1 {
      font-size: 40px;
      line-height: 44px;
    }
    #hero h2 {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 30px;
    }
  }
  @media (max-height: 600px) {
    #hero {
      height: 110vh;
    }
  }
  
  /*--------------------------------------------------------------
  # Sections General
  --------------------------------------------------------------*/
  section {
    padding: 60px 0;
    overflow: hidden;
  }
  
  .section-bg {
    background-color: #f1f7fd;
  }
  
  .section-title {
    text-align: center;
    padding-bottom: 30px;
  }
  .section-title h2 {
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;
    color: #2c4964;
  }
  .section-title h2::before {
    content: "";
    position: absolute;
    display: block;
    width: 120px;
    height: 1px;
    background: #ddd;
    bottom: 1px;
    left: calc(50% - 60px);
  }
  .section-title h2::after {
    content: "";
    position: absolute;
    display: block;
    width: 40px;
    height: 3px;
    background: #1977cc;
    bottom: 0;
    left: calc(50% - 20px);
  }
  .section-title p {
    margin-bottom: 0;
  }
  
  /*--------------------------------------------------------------
  # Breadcrumbs
  --------------------------------------------------------------*/
  .breadcrumbs {
    padding: 20px 0;
    background-color: #f1f7fd;
    min-height: 40px;
    margin-top: 120px;
  }
  @media (max-width: 992px) {
    .breadcrumbs {
      margin-top: 100px;
    }
  }
  .breadcrumbs h2 {
    font-size: 24px;
    font-weight: 300;
    margin: 0;
  }
  @media (max-width: 992px) {
    .breadcrumbs h2 {
      margin: 0 0 10px 0;
    }
  }
  .breadcrumbs ol {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 14px;
  }
  .breadcrumbs ol li + li {
    padding-left: 10px;
  }
  .breadcrumbs ol li + li::before {
    display: inline-block;
    padding-right: 10px;
    color: #6c757d;
    content: "/";
  }
  @media (max-width: 768px) {
    .breadcrumbs .d-flex {
      display: block !important;
    }
    .breadcrumbs ol {
      display: block;
    }
    .breadcrumbs ol li {
      display: inline-block;
    }
  }
  
  /*--------------------------------------------------------------
  # Why Us
  --------------------------------------------------------------*/
  .why-us .content {
    padding: 30px;
    background: #1977cc;
    border-radius: 4px;
    color: #fff;
  }
  .why-us .content h3 {
    font-weight: 700;
    font-size: 32px;
    margin-bottom: 30px;
  }
  .why-us .content p {
    margin-bottom: 30px;
  }
  .why-us .content .more-btn {
    display: inline-block;
    background: rgba(255, 255, 255, 0.2);
    padding: 6px 30px 8px 30px;
    color: #fff;
    border-radius: 50px;
    transition: all ease-in-out 0.4s;
  }
  .why-us .content .more-btn i {
    font-size: 14px;
  }
  .why-us .content .more-btn:hover {
    color: #1977cc;
    background: #fff;
  }
  .why-us .icon-boxes .icon-box {
    /* text-align: center; */
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    padding: 40px 30px;
    width: 100%;
  }
  @media (max-width: 767px) {
    .why-us .icon-boxes .icon-box {
      padding: 30px 20px;
    }
  }
  .why-us .icon-boxes .icon-box i {
    font-size: 40px;
    color: #1977cc;
    margin-bottom: 10px;
  }
  .why-us .icon-boxes .icon-box h4 {
    font-size: 30px;
    font-weight: 700;
    margin: 0 0 30px 0;
  }
  .why-us .icon-boxes .icon-box p {
    font-size: 15px;
    color: #848484;
  }
  
  /*--------------------------------------------------------------
  # About
  --------------------------------------------------------------*/
  .about .icon-boxes h4 {
    font-size: 18px;
    color: #4b7dab;
    margin-bottom: 15px;
  }
  .about .icon-boxes h3 {
    font-size: 28px;
    font-weight: 700;
    color: #2c4964;
    margin-bottom: 15px;
  }
  .about .icon-box:not(:first-of-type) {
    margin-top: 40px;
  }
  .about .icon-box .icon {
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    border: 2px solid #8dc2f1;
    border-radius: 50px;
    transition: 0.5s;
  }
  .about .icon-box .icon i {
    color: #1977cc;
    font-size: 32px;
  }
  /* .about .icon-box:hover .icon {
    background: #1977cc;
    border-color: #1977cc;
  }
  .about .icon-box:hover .icon i {
    color: #fff;
  } */
  .about .icon-box .title {
    margin-left: 85px;
    font-weight: 700;
    margin-bottom: 10px;
    font-size: 18px;
  }
  .about .icon-box .title a {
    color: #343a40;
    transition: 0.3s;
  }
  .about .icon-box .title a:hover {
    color: #1977cc;
  }
  .about .icon-box .description {
    margin-left: 85px;
    line-height: 24px;
    font-size: 14px;
  }
  .about .video-box {
    background: url("/img/about.jpg") center center no-repeat;
    background-size: cover;
    min-height: 500px;
  }
  .about .play-btn {
    width: 94px;
    height: 94px;
    background: radial-gradient(#1977cc 50%, rgba(25, 119, 204, 0.4) 52%);
    border-radius: 50%;
    display: block;
    position: absolute;
    left: calc(50% - 47px);
    top: calc(50% - 47px);
    overflow: hidden;
  }
  .about .play-btn::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-40%) translateY(-50%);
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid #fff;
    z-index: 100;
    transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  .about .play-btn::before {
    content: "";
    position: absolute;
    width: 120px;
    height: 120px;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation: pulsate-btn 2s;
    animation: pulsate-btn 2s;
    -webkit-animation-direction: forwards;
    animation-direction: forwards;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: steps;
    animation-timing-function: steps;
    opacity: 1;
    border-radius: 50%;
    border: 5px solid rgba(25, 119, 204, 0.7);
    top: -15%;
    left: -15%;
    background: rgba(198, 16, 0, 0);
  }
  .about .play-btn:hover::after {
    border-left: 15px solid #1977cc;
    transform: scale(20);
  }
  .about .play-btn:hover::before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-40%) translateY(-50%);
    width: 0;
    height: 0;
    border: none;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid #fff;
    z-index: 200;
    -webkit-animation: none;
    animation: none;
    border-radius: 0;
  }
  
  @-webkit-keyframes pulsate-btn {
    0% {
      transform: scale(0.6, 0.6);
      opacity: 1;
    }
    100% {
      transform: scale(1, 1);
      opacity: 0;
    }
  }
  
  @keyframes pulsate-btn {
    0% {
      transform: scale(0.6, 0.6);
      opacity: 1;
    }
    100% {
      transform: scale(1, 1);
      opacity: 0;
    }
  }
  /*--------------------------------------------------------------
  # Counts
  --------------------------------------------------------------*/
  .counts {
    background: #f1f7fd;
    padding: 70px 0 60px;
  }
  .counts .count-box {
    padding: 30px 30px 25px 30px;
    width: 100%;
    position: relative;
    text-align: center;
    background: #fff;
  }
  .counts .count-box i {
    position: absolute;
    top: -25px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 20px;
    background: #1977cc;
    color: #fff;
    border-radius: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
  }
  .counts .count-box span {
    font-size: 36px;
    display: block;
    font-weight: 600;
    color: #082744;
  }
  .counts .count-box p {
    padding: 0;
    margin: 0;
    font-family: "Raleway", sans-serif;
    font-size: 14px;
  }
  
  /*--------------------------------------------------------------
  # Services
  --------------------------------------------------------------*/
  .services .icon-box {
    text-align: center;
    border: 1px solid #d5e1ed;
    padding: 80px 20px;
    transition: all ease-in-out 0.3s;
  }
  .services .icon-box .icon {
    margin: 0 auto;
    width: 64px;
    height: 64px;
    background: #1977cc;
    border-radius: 5px;
    transition: all 0.3s ease-out 0s;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    transform-style: preserve-3d;
  }
  .services .icon-box .icon i {
    color: #fff;
    font-size: 28px;
  }
  .services .icon-box .icon::before {
    position: absolute;
    content: "";
    left: -8px;
    top: -8px;
    height: 100%;
    width: 100%;
    background: #badaf7;
    border-radius: 5px;
    transition: all 0.3s ease-out 0s;
    transform: translateZ(-1px);
  }
  .services .icon-box h4 {
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 24px;
  }
  .services .icon-box h4 a {
    color: #2c4964;
  }
  .services .icon-box p {
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
  }
  .services .icon-box:hover {
    background: #1977cc;
    border-color: #1977cc;
  }
  .services .icon-box:hover .icon {
    background: #fff;
  }
  .services .icon-box:hover .icon i {
    color: #1977cc;
  }
  .services .icon-box:hover .icon::before {
    background: #3291e6;
  }
  .services .icon-box:hover h4 a, .services .icon-box:hover p {
    color: #fff;
  }
  
  /*--------------------------------------------------------------
  # Appointments
  --------------------------------------------------------------*/
  .appointment .php-email-form {
    width: 100%;
  }
  .appointment .php-email-form .form-group {
    padding-bottom: 8px;
  }
  .appointment .php-email-form .validate {
    display: none;
    color: red;
    margin: 0 0 15px 0;
    font-weight: 400;
    font-size: 13px;
  }
  .appointment .php-email-form .error-message {
    display: none;
    color: #fff;
    background: #ed3c0d;
    text-align: left;
    padding: 15px;
    font-weight: 600;
  }
  .appointment .php-email-form .error-message br + br {
    margin-top: 25px;
  }
  .appointment .php-email-form .sent-message {
    display: none;
    color: #fff;
    background: #18d26e;
    text-align: center;
    padding: 15px;
    font-weight: 600;
  }
  .appointment .php-email-form .loading {
    display: none;
    background: #fff;
    text-align: center;
    padding: 15px;
  }
  .appointment .php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #18d26e;
    border-top-color: #eee;
    -webkit-animation: animate-loading 1s linear infinite;
    animation: animate-loading 1s linear infinite;
  }
  .appointment .php-email-form input, .appointment .php-email-form textarea, .appointment .php-email-form select {
    border-radius: 0;
    box-shadow: none;
    font-size: 14px;
    padding: 10px !important;
  }
  .appointment .php-email-form input:focus, .appointment .php-email-form textarea:focus, .appointment .php-email-form select:focus {
    border-color: #1977cc;
  }
  .appointment .php-email-form input, .appointment .php-email-form select {
    height: 44px;
  }
  .appointment .php-email-form textarea {
    padding: 10px 12px;
  }
  .appointment .php-email-form button[type=submit] {
    background: #1977cc;
    border: 0;
    padding: 10px 35px;
    color: #fff;
    transition: 0.4s;
    border-radius: 50px;
  }
  .appointment .php-email-form button[type=submit]:hover {
    background: #1c84e3;
  }
  
  /*--------------------------------------------------------------
  # Departments
  --------------------------------------------------------------*/
  .departments {
    overflow: hidden;
  }
  .departments .nav-tabs {
    border: 0;
  }
  .departments .nav-link {
    border: 0;
    padding: 12px 15px 12px 0;
    transition: 0.3s;
    color: #2c4964;
    border-radius: 0;
    border-right: 2px solid #ebf1f6;
    font-weight: 600;
    font-size: 15px;
  }
  .departments .nav-link:hover {
    color: #1977cc;
  }
  .departments .nav-link.active {
    color: #1977cc;
    border-color: #1977cc;
  }
  .departments .tab-pane.active {
    -webkit-animation: fadeIn 0.5s ease-out;
    animation: fadeIn 0.5s ease-out;
  }
  .departments .details h3 {
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 20px;
    color: #2c4964;
  }
  .departments .details p {
    color: #777777;
  }
  .departments .details p:last-child {
    margin-bottom: 0;
  }
  @media (max-width: 992px) {
    .departments .nav-link {
      border: 0;
      padding: 15px;
    }
    .departments .nav-link.active {
      color: #fff;
      background: #1977cc;
    }
  }
  
  /*--------------------------------------------------------------
  # Doctors
  --------------------------------------------------------------*/
  .doctors {
    background: #fff;
  }
  .doctors .member {
    position: relative;
    box-shadow: 0px 2px 15px rgba(44, 73, 100, 0.08);
    padding: 30px;
    border-radius: 10px;
  }
  .doctors .member .pic {
    overflow: hidden;
    width: 100px;
    border-radius: 50%;
    margin-bottom: 0;
  }
  .doctors .member .pic img {
    transition: ease-in-out 0.3s;
  }
  .doctors .member:hover img {
    transform: scale(1.1);
  }
  .doctors .member .member-info {
    padding-left: 30px;
  }
  @media(max-width: 767px) {
    .doctors .member .member-info {
      padding-left: 0;
    }
    .doctors .member .pic {
      margin-bottom: 1rem;
    }
  }
  .doctors .member h4 {
    font-weight: 700;
    margin-bottom: 5px;
    font-size: 20px;
    color: #2c4964;
  }
  .doctors .member span {
    display: block;
    font-size: 15px;
    padding-bottom: 10px;
    position: relative;
    font-weight: 500;
  }
  .doctors .member span::after {
    content: "";
    position: absolute;
    display: block;
    width: 50px;
    height: 1px;
    background: #b2c8dd;
    bottom: 0;
    left: 0;
  }
  .doctors .member p {
    margin: 10px 0 0 0;
    font-size: 14px;
  }
  .doctors .member .social {
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .doctors .member .social a {
    transition: ease-in-out 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    width: 32px;
    height: 32px;
    background: #a0bcd5;
  }
  .doctors .member .social a i {
    color: #fff;
    font-size: 16px;
    margin: 0 2px;
  }
  .doctors .member .social a:hover {
    background: #1977cc;
  }
  .doctors .member .social a + a {
    margin-left: 8px;
  }
  
  /*--------------------------------------------------------------
  # Frequently Asked Questions
  --------------------------------------------------------------*/
  .faq .faq-list {
    padding: 0 100px;
  }
  .faq .faq-list ul {
    padding: 0;
    list-style: none;
  }
  .faq .faq-list li + li {
    margin-top: 15px;
  }
  .faq .faq-list li {
    padding: 20px;
    background: #fff;
    border-radius: 4px;
    position: relative;
  }
  .faq .faq-list a {
    display: block;
    position: relative;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    padding: 0 30px;
    outline: none;
    cursor: pointer;
  }
  .faq .faq-list .icon-help {
    font-size: 24px;
    position: absolute;
    right: 0;
    left: 20px;
    color: #76b5ee;
  }
  .faq .faq-list .icon-show, .faq .faq-list .icon-close {
    font-size: 24px;
    position: absolute;
    right: 0;
    top: 0;
  }
  .faq .faq-list p {
    margin-bottom: 0;
    padding: 10px 0 0 0;
  }
  .faq .faq-list .icon-show {
    display: none;
  }
  .faq .faq-list a.collapsed {
    color: #343a40;
  }
  .faq .faq-list a.collapsed:hover {
    color: #1977cc;
  }
  .faq .faq-list a.collapsed .icon-show {
    display: inline-block;
  }
  .faq .faq-list a.collapsed .icon-close {
    display: none;
  }
  @media (max-width: 1200px) {
    .faq .faq-list {
      padding: 0;
    }
  }
  
  /*--------------------------------------------------------------
  # Testimonials
  --------------------------------------------------------------*/
  .testimonials .testimonial-wrap {
    padding-left: 50px;
  }
  .testimonials .testimonials-carousel, .testimonials .testimonials-slider {
    overflow: hidden;
  }
  .testimonials .testimonial-item {
    box-sizing: content-box;
    padding: 30px 30px 30px 60px;
    margin: 30px 15px;
    min-height: 200px;
    box-shadow: 0px 2px 12px rgba(44, 73, 100, 0.08);
    position: relative;
    background: #fff;
  }
  .testimonials .testimonial-item .testimonial-img {
    width: 90px;
    border-radius: 10px;
    border: 6px solid #fff;
    position: absolute;
    left: -45px;
  }
  .testimonials .testimonial-item h3 {
    font-size: 18px;
    font-weight: bold;
    margin: 10px 0 5px 0;
    color: #111;
  }
  .testimonials .testimonial-item h4 {
    font-size: 14px;
    color: #999;
    margin: 0;
  }
  .testimonials .testimonial-item .quote-icon-left, .testimonials .testimonial-item .quote-icon-right {
    color: #badaf7;
    font-size: 26px;
  }
  .testimonials .testimonial-item .quote-icon-left {
    display: inline-block;
    left: -5px;
    position: relative;
  }
  .testimonials .testimonial-item .quote-icon-right {
    display: inline-block;
    right: -5px;
    position: relative;
    top: 10px;
  }
  .testimonials .testimonial-item p {
    font-style: italic;
    margin: 15px auto 15px auto;
  }
  .testimonials .swiper-pagination {
    margin-top: 20px;
    position: relative;
  }
  .testimonials .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: #fff;
    opacity: 1;
    border: 1px solid #1977cc;
  }
  .testimonials .swiper-pagination .swiper-pagination-bullet-active {
    background-color: #1977cc;
  }
  @media (max-width: 767px) {
    .testimonials .testimonial-wrap {
      padding-left: 0;
    }
    .testimonials .testimonials-carousel, .testimonials .testimonials-slider {
      overflow: hidden;
    }
    .testimonials .testimonial-item {
      padding: 30px;
      margin: 15px;
    }
    .testimonials .testimonial-item .testimonial-img {
      position: static;
      left: auto;
    }
  }
  
  /*--------------------------------------------------------------
  # Gallery
  --------------------------------------------------------------*/
  .gallery .gallery-item {
    overflow: hidden;
    border-right: 3px solid #fff;
    border-bottom: 3px solid #fff;
  }
  .gallery .gallery-item img {
    transition: all ease-in-out 0.4s;
  }
  .gallery .gallery-item:hover img {
    transform: scale(1.1);
  }
  
  /*--------------------------------------------------------------
  # Contact
  --------------------------------------------------------------*/
  .contact {
    background-color: #F1F7FD;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    margin-top: 80px;
  }
  .contact .info {
    width: 100%;
    background: #fff;
  }
  .contact .info i {
    font-size: 20px;
    color: #1977cc;
    float: left;
    width: 44px;
    height: 44px;
    background: #d6e9fa;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
  }
  .contact .info h4 {
    padding: 0 0 0 60px;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 5px;
    color: #2c4964;
  }
  .contact .info p {
    padding: 0 0 0 60px;
    margin-bottom: 0;
    font-size: 14px;
    color: #4b7dab;
  }
  .contact .info .email, .contact .info .phone {
    margin-top: 40px;
  }
  .contact .info .email:hover i, .contact .info .address:hover i, .contact .info .phone:hover i {
    background: #1977cc;
    color: #fff;
  }
  .contact .php-email-form {
    width: 100%;
    background: #F1F7FD;;
  }
  .contact .php-email-form .form-group {
    padding-bottom: 8px;
  }
  .contact .php-email-form .error-message {
    display: none;
    color: #fff;
    background: #ed3c0d;
    text-align: left;
    padding: 15px;
    font-weight: 600;
  }
  .contact .php-email-form .error-message br + br {
    margin-top: 25px;
  }
  .contact .php-email-form .sent-message {
    display: none;
    color: #fff;
    background: #18d26e;
    text-align: center;
    padding: 15px;
    font-weight: 600;
  }
  .contact .php-email-form .loading {
    display: none;
    background: #fff;
    text-align: center;
    padding: 15px;
  }
  .contact .php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid #18d26e;
    border-top-color: #eee;
    -webkit-animation: animate-loading 1s linear infinite;
    animation: animate-loading 1s linear infinite;
  }
  .contact .php-email-form input, .contact .php-email-form textarea {
    border-radius: 0;
    box-shadow: none;
  }

  .contact .php-email-form textarea {
    padding: 10px 12px;
  }
  .contact .php-email-form button[type=submit] {
    background: #1977cc;
    border: 2px solid #1977cc;
    padding: 15px 35px;
    color: #fff;
    transition: 0.4s;
    border-radius: 50px;
    min-width: 200px;
  }
  .contact .php-email-form button[type=submit].btn-back {
    background: transparent;
    border: 2px solid #1977cc;
    color: #1977cc;
  }
  .contact .php-email-form button[type=submit]:hover {
    background: #1c84e3;
  }
  .contact .php-email-form button[type=submit]:hover.btn-back {
    background: #1977cc;
    color: #fff;
  }
  @-webkit-keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes animate-loading {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .contact .form-control {
    background-color: #fff;
  }
  .contact .form-confirm .form-control {
    background-color: rgba(255, 255, 255, 0.5);
  }
  /*--------------------------------------------------------------
  # Footer
  --------------------------------------------------------------*/
  #footer {
    color: #444444;
    font-size: 14px;
    background: #f1f7fd;
  }
  #footer .footer-top {
    padding: 60px 0 30px 0;
    background: #fff;
    box-shadow: 0px 2px 15px rgba(25, 119, 204, 0.1);
  }
  #footer .footer-top .footer-contact {
    margin-bottom: 30px;
  }
  #footer .footer-top .footer-contact h4 {
    font-size: 22px;
    margin: 0 0 30px 0;
    padding: 2px 0 2px 0;
    line-height: 1;
    font-weight: 700;
  }
  #footer .footer-top .footer-contact p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 0;
    font-family: "Raleway", sans-serif;
    color: #777777;
  }
  #footer .footer-top h4 {
    font-size: 16px;
    font-weight: bold;
    color: #444444;
    position: relative;
    padding-bottom: 12px;
  }
  #footer .footer-top .footer-links {
    margin-bottom: 30px;
  }
  #footer .footer-top .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  #footer .footer-top .footer-links ul i {
    padding-right: 2px;
    color: #1c84e3;
    font-size: 18px;
    line-height: 1;
  }
  #footer .footer-top .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
  }
  #footer .footer-top .footer-links ul li:first-child {
    padding-top: 0;
  }
  #footer .footer-top .footer-links ul a {
    color: #777777;
    transition: 0.3s;
    display: inline-block;
    line-height: 1;
  }
  #footer .footer-top .footer-links ul a:hover {
    text-decoration: none;
    color: #1977cc;
  }
  #footer .footer-newsletter {
    font-size: 15px;
  }
  #footer .footer-newsletter h4 {
    font-size: 16px;
    font-weight: bold;
    color: #444444;
    position: relative;
    padding-bottom: 12px;
  }
  #footer .footer-newsletter form {
    margin-top: 30px;
    background: #fff;
    padding: 6px 10px;
    position: relative;
    border-radius: 50px;
    text-align: left;
    border: 1px solid #bfdcf7;
  }
  #footer .footer-newsletter form input[type=email] {
    border: 0;
    padding: 4px 8px;
    width: calc(100% - 100px);
  }
  #footer .footer-newsletter form input[type=submit] {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border: 0;
    background: none;
    font-size: 16px;
    padding: 0 20px;
    background: #1977cc;
    color: #fff;
    transition: 0.3s;
    border-radius: 50px;
  }
  #footer .footer-newsletter form input[type=submit]:hover {
    background: #1c84e3;
  }
  #footer .credits {
    padding-top: 5px;
    font-size: 13px;
    color: #444444;
  }
  #footer .social-links a {
    font-size: 18px;
    display: inline-block;
    background: #1977cc;
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
  }
  #footer .social-links a:hover {
    background: #1c84e3;
    color: #fff;
    text-decoration: none;
  }

.body-logged {
    background-color: #F1F7FD;
    min-height: 100vh;
    padding: 40px 0;
}
.section-logged {
    margin-top: 15px;
    padding: 0;
}
.section-logged .container {
    max-width: 600px;
    margin: 0 auto;
}
.section-logged .card-box {
    background-color: #fff;
    box-shadow: 0 3px 16px rgba(0, 0, 0, 0.08);
}
.section-logged .card-box .card-title {
    border-top: 5px solid #004BB1;
    background-color: #DAE7FA;
    color:#004BB1;
    padding: 20px;
}
.section-logged .card-box .card-content {
    padding-left: 20px;
    padding-right: 20px;
}
.card-content {
    padding-top: 30px;
    padding-bottom: 30px;
}
.card-content-booked {
    border-bottom: 1px solid #DAE7FA;
    margin-bottom: 30px;
}
.card-content-news {
    border-bottom: 1px solid #DAE7FA;
    margin-bottom: 10px;
}
.doctor-details.active {
    display: block;
}
.doctor-details dl {
    margin-top: 10px;
}
.doctor-details dt {
    color: #234272;
}
.doctor-details dd {
    opacity: 0.7;
    font-size: 0.9rem;
}
.bookingstep1 .accordion-button {
    flex-direction: column;
}
.bookingstep1 .accordion-button::after {
    margin-left:auto;
    margin-right:auto;
    bottom: 0;
}
.bookingstep1 .accordion-item {
    border: none;
}
.bookingstep1 .accordion-item:not(:last-of-type) {
    border-bottom: 1px solid #d6dbe1;
}
.bookingstep1 .status {
    font-size: 12px;
    display: block;
}
.btn-set {
    display: flex;
    flex-basis: auto;
    gap: 10px;
}

/* creditcard animation */
.form-container {
  margin: 0 auto;
}
.form-container .field-container:first-of-type {
  grid-area: name;
}

.form-container .field-container:nth-of-type(2) {
  grid-area: number;
}

.form-container .field-container:nth-of-type(3) {
  grid-area: expiration;
}

.form-container .field-container:nth-of-type(4) {
  grid-area: security;
}

.field-container input {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.field-container {
  position: relative;
}

.form-container {
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: auto auto;
  grid-template-rows: 90px 90px 90px;
  grid-template-areas: "name name""number number""expiration security";
  max-width: 400px;
  padding: 20px;
  color: #707070;
}

.field-container label {
  padding-bottom: 5px;
  font-size: 13px;
}

.field-container input {
  margin-top: 3px;
  padding: 15px;
  font-size: 16px;
  width: 100%;
  border-radius: 3px;
  border: 1px solid #dcdcdc;
}

.ccicon {
  height: 38px;
  position: absolute;
  right: 10px;
  top: calc(50% - 4px);
  width: 60px;
}

/* CREDIT CARD IMAGE STYLING */
.preload * {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -ms-transition: none !important;
  -o-transition: none !important;
}

.card-container {
  width: 100%;
  max-width: 400px;
  max-height: 251px;
  height: 54vw;
  padding: 20px;
  margin: 0 auto;
}

#ccsingle {
  position: absolute;
  right: 15px;
  top: 20px;
}

#ccsingle svg {
  width: 100px;
  max-height: 60px;
}

.creditcard svg#cardfront,
.creditcard svg#cardback {
  width: 100%;
  -webkit-box-shadow: 1px 5px 6px 0px black;
  box-shadow: 1px 5px 6px 0px black;
  border-radius: 22px;
}

#generatecard{
  cursor: pointer;
  float: right;
  font-size: 12px;
  color: #fff;
  padding: 2px 4px;
  background-color: #909090;
  border-radius: 4px;
  cursor: pointer;
  float:right;
}

/* CHANGEABLE CARD ELEMENTS */
.creditcard .lightcolor,
.creditcard .darkcolor {
  -webkit-transition: fill .5s;
  transition: fill .5s;
}

.creditcard .lightblue {
  fill: #03A9F4;
}

.creditcard .lightbluedark {
  fill: #0288D1;
}

.creditcard .red {
  fill: #ef5350;
}

.creditcard .reddark {
  fill: #d32f2f;
}

.creditcard .purple {
  fill: #ab47bc;
}

.creditcard .purpledark {
  fill: #7b1fa2;
}

.creditcard .cyan {
  fill: #26c6da;
}

.creditcard .cyandark {
  fill: #0097a7;
}

.creditcard .green {
  fill: #66bb6a;
}

.creditcard .greendark {
  fill: #388e3c;
}

.creditcard .lime {
  fill: #d4e157;
}

.creditcard .limedark {
  fill: #afb42b;
}

.creditcard .yellow {
  fill: #ffeb3b;
}

.creditcard .yellowdark {
  fill: #f9a825;
}

.creditcard .orange {
  fill: #ff9800;
}

.creditcard .orangedark {
  fill: #ef6c00;
}

.creditcard .grey {
  fill: #bdbdbd;
}

.creditcard .greydark {
  fill: #616161;
}

/* FRONT OF CARD */
#svgname {
  text-transform: uppercase;
}

#cardfront .st2 {
  fill: #FFFFFF;
}

#cardfront .st3 {
  font-family: 'Source Code Pro', monospace;
  font-weight: 600;
}

#cardfront .st4 {
  font-size: 54.7817px;
}

#cardfront .st5 {
  font-family: 'Source Code Pro', monospace;
  font-weight: 400;
}

#cardfront .st6 {
  font-size: 33.1112px;
}

#cardfront .st7 {
  opacity: 0.6;
  fill: #FFFFFF;
}

#cardfront .st8 {
  font-size: 24px;
}

#cardfront .st9 {
  font-size: 36.5498px;
}

#cardfront .st10 {
  font-family: 'Source Code Pro', monospace;
  font-weight: 300;
}

#cardfront .st11 {
  font-size: 16.1716px;
}

#cardfront .st12 {
  fill: #4C4C4C;
}

/* BACK OF CARD */
#cardback .st0 {
  fill: none;
  stroke: #0F0F0F;
  stroke-miterlimit: 10;
}

#cardback .st2 {
  fill: #111111;
}

#cardback .st3 {
  fill: #F2F2F2;
}

#cardback .st4 {
  fill: #D8D2DB;
}

#cardback .st5 {
  fill: #C4C4C4;
}

#cardback .st6 {
  font-family: 'Source Code Pro', monospace;
  font-weight: 400;
}

#cardback .st7 {
  font-size: 27px;
}

#cardback .st8 {
  opacity: 0.6;
}

#cardback .st9 {
  fill: #FFFFFF;
}

#cardback .st10 {
  font-size: 24px;
}

#cardback .st11 {
  fill: #EAEAEA;
}

#cardback .st12 {
  font-family: 'Rock Salt', cursive;
}

#cardback .st13 {
  font-size: 37.769px;
}

/* FLIP ANIMATION */
.card-container {
  perspective: 1000px;
}

.creditcard {
  width: 100%;
  max-width: 400px;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  cursor: pointer;
  transition: -webkit-transform 0.6s;
  -webkit-transition: -webkit-transform 0.6s;
  transition: transform 0.6s;
  transition: transform 0.6s, -webkit-transform 0.6s;
}

.creditcard .front,
.creditcard .back {
  position: absolute;
  width: 100%;
  max-width: 400px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
  color: #47525d;
}

.creditcard .back {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.creditcard.flipped {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
}
/* creditcard animation :end */

/* admin view */
/* .body-logged .main {
  padding-top: 80px;
}
.body-logout .main {
  padding-top: 120px;
  height: 100vh;
  background-color: #F1F7FD;
} */
/* #main {
  background-color: #fff;
} */

/*--------------------------------------------------------------
# 利用流れ
--------------------------------------------------------------*/
.steps .title {
  color: #383b3f;
  font-weight: 700;
  font-size: 18px;
}
.steps .head-title {
  color: #4b7dab;
  font-weight: 700;
  font-size: 18px;
}